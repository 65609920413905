
import { NgModule } from '@angular/core';
import { GetVarDirective } from './get-var.directive';

@NgModule({
  declarations: [
    GetVarDirective
  ],
  exports: [
    GetVarDirective
  ]
})
export class GetVarModule { }
